








import { Component, Prop, Vue } from 'vue-property-decorator'
import DecorateWidget from '@/components/decorate/decorate-widget.vue'
import DecoratePhone from '@/components/decorate/decorate-phone.vue'
import DecorateAttr from '@/components/decorate/decorate-attr.vue'

@Component({
    components: {
        DecorateWidget,
        DecoratePhone,
        DecorateAttr
    }
})
export default class DecorateIndex extends Vue {
    /** S data **/
    id!: any
    component = [
        {
            name: 'adv'
        },
        {
            name: 'header',
            operate: ['hidden']
        },
        {
            name: 'footer',
            operate: ['hidden']
        },
        {
            name: 'goodsgroup'
        },
        {
            name: 'fixed',
            operate: ['hidden']
        },
        {
            name: 'banner',
            operate: ['hidden']
        },
        {
            name: 'seckill'
        }
    ]

    pagesInfo = {
        name: 'PC商城首页',
        common: {}
    }

    pageData = [
        {
            title: '头部',
            name: 'header',
            show: 1,
            forbid: true,
            content: {
                data: []
            },
            style: {}
        },
        {
            title: '轮播',
            name: 'banner',
            show: 1,
            forbid: true,
            content: {
                data: [
                    {
                        url: '',
                        link: {}
                    }
                ]
            },
            style: {}
        },
        {
            title: '底部',
            name: 'footer',
            show: 1,
            forbid: true,
            content: {
                data: [
                    {
                        url: '',
                        name: ''
                    },
                    {
                        url: '',
                        name: ''
                    },
                    {
                        url: '',
                        name: ''
                    },
                    {
                        url: '',
                        name: ''
                    },
                    {
                        url: '',
                        name: ''
                    }
                ]
            },
            style: {}
        },
        {
            title: '固定导航',
            name: 'fixed',
            show: 1,
            forbid: true,
            content: {
                style: 1,
                data: [
                    {
                        type: 'nav',
                        icon: '',
                        select_icon: '',
                        name: '',
                        link: {}
                    }
                ]
            },
            style: {}
        }
    ]
    /** E data **/

    /** S computed **/

    /** E computed **/

    /** S methods **/

    async getPages() {
        // this.$store.commit('setPagesInfo', this.pagesInfo)
        // this.$store.commit('setPagesData', this.pageData)
        this.$store.commit('setClient', 'pc')

        if (this.id) {
            this.$store.dispatch('getPages', { id: this.id })
        }
    }
    /** E methods **/
    /** S life cycle **/
    created() {
        this.id = this.$route.query.id
        document.body.setAttribute('style', 'min-width: 1800px')
        this.getPages()
    }

    beforeDestroy() {
        document.body.setAttribute('style', '')
    }
    /** E life cycle **/
}
